<template>
  <div>
    <div class="logo">
      cloudpay
    </div>
    <div class="container">
      <div class="content">
        <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" class="icon-fail">
          <!-- SVG path code here -->
          <path
              d="M24,4 C35.045695,4 44,12.954305 44,24 C44,35.045695 35.045695,44 24,44 C12.954305,44 4,35.045695 4,24 C4,12.954305 12.954305,4 24,4 Z M32.57818,15.42182 C32.0157534,14.8593933 31.1038797,14.8593933 30.541453,15.42182 L30.541453,15.42182 L24.0006789,21.9625941 L17.458547,15.42182 C16.8961203,14.8593933 15.9842466,14.8593933 15.42182,15.42182 C14.8593933,15.9842466 14.8593933,16.8961203 15.42182,17.458547 L15.42182,17.458547 L21.9639519,23.9993211 L15.42182,30.541453 C14.8593933,31.1038797 14.8593933,32.0157534 15.42182,32.57818 C15.9842466,33.1406067 16.8961203,33.1406067 17.458547,32.57818 L17.458547,32.57818 L24.0006789,26.0360481 L30.541453,32.57818 C31.1038797,33.1406067 32.0157534,33.1406067 32.57818,32.57818 C33.1406067,32.0157534 33.1406067,31.1038797 32.57818,30.541453 L32.57818,30.541453 L26.0374059,23.9993211 L32.57818,17.458547 C33.1406067,16.8961203 33.1406067,15.9842466 32.57818,15.42182 Z"></path>
        </svg>
        <p>Pay Fail</p>
        <!--      <button class="button">return</button>-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="css" scoped>

.container {
  padding: 0 0.5rem;
}

.logo {
  padding: 0.1rem;
  background-color: #FFFFFF;
  font-size: 0.5rem;
  font-weight: bold;
}

.content {
  width: 100%;
  padding: 20px;
  margin-top: 30px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(128, 128, 128, 0.2);
  border-radius: 0.3rem;
}

.content p {
  font-size: 32px;
}

.content svg {
  width: 1.5rem;
  height: 1.5rem;
}

.icon-fail {
  fill: #f56c6c;
}

.button {
  margin-top: 60px;
  display: inline-block;
  padding: 5px 20px;
  border: 1px solid #69bf4e;
  background-color: #FFFFFF;
  border-radius: 30px;
  color: #69bf4e;
  text-align: center;
  font-size: 16px;
}
</style>
